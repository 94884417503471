<template>
  <ul>
    <li v-for="(game_info, idx) in game_infos" :key="idx">
      <router-link
        :to="{ name: 'game', params: { identifier: game_info.identifier } }"
        >{{ game_info["name"]["zh-Hans"] }}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "GamesLinkList",
  props: ["game_infos"],
};
</script>
