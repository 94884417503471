<template>
  <div class="container mt-3" v-once>
    <h3>游戏列表</h3>
    <p>
      <GamesLinkList :game_infos="game_infos" />
    </p>
  </div>
</template>

<script>
import GamesLinkList from "@/components/GamesLinkList";
import gameDataJson from "@/utils/info/gameDataJson";

export default {
  name: "games",
  metaInfo: {
    title: "游戏列表",
    meta: [
      { name: "description", content: "在线 DOS 游戏的游戏列表" },
      { name: "keywords", content: "在线, DOS, 游戏, 在线DOS游戏" },
      { property: "og:title", content: "游戏列表 - 在线 DOS 游戏" },
      { property: "og:url", content: "https://dos.zczc.cz/games/" },
      { property: "og:description", content: "在线 DOS 游戏的游戏列表" },
    ],
    link: [{ rel: "canonical", href: "https://dos.zczc.cz/games/" }],
    script: [
      {
        type: "application/ld+json",
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              name: "游戏列表",
              item: "https://dos.zczc.cz/games/",
            },
          ],
        },
      },
    ],
  },
  computed: {
    game_infos: () => Object.values(gameDataJson),
  },
  components: {
    GamesLinkList,
  },
};
</script>
